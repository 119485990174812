textPath {
    display: none;
}

.highcharts-collapse-button {
    margin-left: 200px !important; /* Apply margin-left */
}


.rd3t-node {
    cursor: pointer;
    fill: #777;  /* Node background */
    stroke: revert !important; /* Node border */
    stroke-width: 2;
}

.rd3t-node text {
    stroke: none !important;
    fill: black;  /* Ensures readable text */
}

.rd3t-node text {
  fill: blue;  /* Ensures readable text */
}
.topologyElipse {
  white-space: nowrap;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  direction: ltr;
}
.topologyKeyElipse {
  white-space: nowrap;
  max-width: 378px;
  text-overflow: ellipsis;
}
.overflowing:hover {
  direction: rtl;
  text-align: right;
}
.subscriptionClass:hover {
  direction: rtl;
  text-align: right;
}
.topologyFilterButtons {
  padding: 2px 0px;
  cursor: pointer;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}
.topologyPopupPosition {
  right: 46px;
  top: 120px;
  width: 378px;
}
.rd3t-leaf-node {
    stroke: none !important;
}
.topologyItemsWidth {
  max-width: 340px;
}
#smallGreenDot {
  color: #2AB75A;
  background-color: #2AB75A;
  box-shadow: 0px 0px 6px 0px #2AB75A;
  border-radius: 50%;
  margin-right: 7px;
  width: 4px;
  height: 4px;
  display: inline-flex;
 }
 #smallRedDot {
  color: red;
  background-color: red;
  box-shadow: 0px 0px 6px 0px red;
  border-radius: 50%;
  margin-right: 7px;
  width: 4px;
  height: 4px;
  display: inline-flex;
 }
 .colorBlue {
  color:blue;
 }
 .selectIcon .MuiSelect-icon {
  color: #D9D9D9;
 }
 .selectIcon .MuiSelect-select {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0.1px;
  vertical-align: middle;
 }
 .overlay-container .topologuPopupHeader {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0.1px;
  vertical-align: middle;
 }
 .overlay-container .topologuPopupSecondHeader {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 22.4px;
  letter-spacing: 0.1px;
  vertical-align: middle;
 }
 .topologyDetailsMaxwidth {
  max-width: 340px;
 }
 .topologyDetailsMaxwidth330 {
  max-width: 330px;
 }

.custom-link {
    stroke:  #a0a0a0 !important;
    stroke-width: 2px;
    fill: none;
  }
  
  .rd3t-label {
    dominant-baseline: text-before-edge; /* Moves labels above links */
  }

  .topologyPopUpSelect {
    background: white !important;
  }


  .rd3t-link.green-link {
    stroke: green !important;
    stroke-width: 2px;

  }

  .rd3t-link.red-link {
    stroke: red !important;
    stroke-width: 2px;

  }